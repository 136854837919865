import {
	PRODUCT_TYPE_BEANIE_LOWER,
	PRODUCT_TYPE_BIKINI_BOTTOM_LOWER,
	PRODUCT_TYPE_BIKINI_LACE_LOWER,
	PRODUCT_TYPE_BIKINI_LACE_PACK_LOWER,
	PRODUCT_TYPE_BIKINI_LOWER,
	PRODUCT_TYPE_BIKINI_PACK_LOWER,
	PRODUCT_TYPE_BIKINI_TOP_LOWER,
	PRODUCT_TYPE_BOWTIE_LOWER,
	PRODUCT_TYPE_BOXERS_BRIEF_LOWER,
	PRODUCT_TYPE_BOXERS_BRIEF_PACK_LOWER,
	PRODUCT_TYPE_BOXERS_COOLING_LONG_PACK_LOWER,
	PRODUCT_TYPE_BOXERS_COOLING_LOWER,
	PRODUCT_TYPE_BOXERS_COOLING_PACK_LOWER,
	PRODUCT_TYPE_BOXERS_FLY_LOWER,
	PRODUCT_TYPE_BOXERS_FLY_PACK_LOWER,
	PRODUCT_TYPE_BOXERS_LONG_FLY_LOWER,
	PRODUCT_TYPE_BOXERS_LONG_FLY_PACK_LOWER,
	PRODUCT_TYPE_BOXERS_LOOSE_LOWER,
	PRODUCT_TYPE_BOXERS_LOOSE_PACK_LOWER,
	PRODUCT_TYPE_BOXERS_LOWER,
	PRODUCT_TYPE_BOXERS_PACK_LOWER,
	PRODUCT_TYPE_BOXERS_TRUNK_LOWER,
	PRODUCT_TYPE_BOXERS_TRUNK_PACK_LOWER,
	PRODUCT_TYPE_BOXER_BRIEF_PACK_LOWER,
	PRODUCT_TYPE_BOXER_FLY_PACK_LOWER,
	PRODUCT_TYPE_BOXER_LONG_FLY_PACK_LOWER,
	PRODUCT_TYPE_BOXER_PACK_LOWER,
	PRODUCT_TYPE_BOXER_TRUNK_PACK_LOWER,
	PRODUCT_TYPE_BOYSHORT_LOWER,
	PRODUCT_TYPE_BOYSHORT_PACK_LOWER,
	PRODUCT_TYPE_BOYS_UNDERWEAR_PACK_LOWER,
	PRODUCT_TYPE_BRALETTES_COOLING_LOWER,
	PRODUCT_TYPE_BRALETTES_COOLING_PACK_LOWER,
	PRODUCT_TYPE_BRALETTES_LOWER,
	PRODUCT_TYPE_BRALETTES_PACK_LOWER,
	PRODUCT_TYPE_BRALETTES_TRIANGLE_LOWER,
	PRODUCT_TYPE_CHEEKY_LOWER,
	PRODUCT_TYPE_HAWAIIAN_LOWER,
	PRODUCT_TYPE_LAUNDRY_SHEETS_LOWER,
	PRODUCT_TYPE_LONG_JOHNS_LOWER,
	PRODUCT_TYPE_LONG_JOHNS_PACK_LOWER,
	PRODUCT_TYPE_LOUNGE_MENS_HOODIE_LOWER,
	PRODUCT_TYPE_LOUNGE_MENS_HOODIE_PACK_LOWER,
	PRODUCT_TYPE_LOUNGE_MENS_JOGGER_LOWER,
	PRODUCT_TYPE_LOUNGE_MENS_JOGGER_PACK_LOWER,
	PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE_LOWER,
	PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE_PACK_LOWER,
	PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER_LOWER,
	PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER_PACK_LOWER,
	PRODUCT_TYPE_ONE_PIECE_LOWER,
	PRODUCT_TYPE_POLO_LOWER,
	PRODUCT_TYPE_SLEEP_MENS_BOTTOMS_LOWER,
	PRODUCT_TYPE_SLEEP_MENS_BOTTOMS_PACK_LOWER,
	PRODUCT_TYPE_SLEEP_MENS_TOPS_LOWER,
	PRODUCT_TYPE_SLEEP_MENS_TOPS_PACK_LOWER,
	PRODUCT_TYPE_SLEEP_WOMENS_DRESS_LOWER,
	PRODUCT_TYPE_SLEEP_WOMENS_DRESS_PACK_LOWER,
	PRODUCT_TYPE_SLEEP_WOMENS_SETS_LOWER,
	PRODUCT_TYPE_SLEEP_WOMENS_SETS_PACK_LOWER,
	PRODUCT_TYPE_SOCKS_ANKLE_LOWER,
	PRODUCT_TYPE_SOCKS_ANKLE_PACK_LOWER,
	PRODUCT_TYPE_SOCKS_CREW_LOWER,
	PRODUCT_TYPE_SOCKS_CREW_PACK_LOWER,
	PRODUCT_TYPE_SWIM_BRIEF_LOWER,
	PRODUCT_TYPE_SWIM_BRIEF_POUCH_LOWER,
	PRODUCT_TYPE_THONGS_COOLING_LOWER,
	PRODUCT_TYPE_THONGS_COOLING_PACK_LOWER,
	PRODUCT_TYPE_THONGS_LOWER,
	PRODUCT_TYPE_THONGS_MODAL_LOWER,
	PRODUCT_TYPE_THONGS_MODAL_PACK_LOWER,
	PRODUCT_TYPE_THONGS_PACK_LOWER,
	PRODUCT_TYPE_THONG_LACE_PACK_LOWER,
	PRODUCT_TYPE_TIE_LOWER,
	PRODUCT_TYPE_T_SHIRTS_LOWER,
	PRODUCT_TYPE_T_SHIRTS_PACK_LOWER,
	PRODUCT_TYPE_WOMENS_BOXER_LOWER,
	PRODUCT_TYPE_WOMENS_BOXER_PACK_LOWER,
} from './product-types';

const BASIC_PDP_CONFIG = {
	defaultDetailsDrawerOpen: false,
	showCustomersAlsoBought: true,
	showCrossSell: true,
	showRecommendedProducts: true,
	showReviews: true,
};

const DEFAULT_PDP_CONFIG = {
	...BASIC_PDP_CONFIG,
	detailsComponent: 'h3',
	imageHeight: 500,
	showExpandableDetails: true,
};

const ICON_BALL_HAMMOCK = 'https://cdn.shinesty.com/2023-11-10/ball-hammock-eggs-icon.svg';
const ICON_BIKINI_RISE = 'https://cdn.shinesty.com/2023-11-10/bikini-rise-icon.svg';
const ICON_BOOB_LIFT = 'https://cdn.shinesty.com/2023-11-10/boob-lift-icon.svg';
const ICON_BOXER_CUT = 'https://cdn.shinesty.com/2024-04-03/underwearWBoxerOutline.svg';
const ICON_BOYSHORT_RISE = 'https://cdn.shinesty.com/2023-11-10/boyshort-rise-icon.svg';
const ICON_CHEEKY_RISE = 'https://cdn.shinesty.com/2023-11-10/cheeky-rise-icon.svg';
const ICON_COOLING_SNOWFLAKE = 'https://cdn.shinesty.com/2023-11-10/cooling-snowflake-icon.svg';
const ICON_FABRIC_LACE = 'https://cdn.shinesty.com/2023-11-10/fabric-lace-icon.svg';
const ICON_FEATHER = 'https://cdn.shinesty.com/2024-07-22/Feather.svg';
const ICON_HEART_OR_FREE = 'https://cdn.shinesty.com/2023-11-10/love-em-heart-icon.svg';
const ICON_LOUNGE_FIT = 'https://cdn.shinesty.com/2024-10-23/lounge-fit.svg';
const ICON_LUX_FEATHER = 'https://cdn.shinesty.com/2023-11-10/lux-feather-icon.svg';
const ICON_MEASURING = 'https://cdn.shinesty.com/2023-11-10/just-right-measure-icon.svg';
const ICON_MOISTURE_WICKING = 'https://cdn.shinesty.com/2023-11-10/breathable-icon.svg';
const ICON_PIGGY_BANK = 'https://cdn.shinesty.com/2024-07-22/PiggyBank.svg';
const ICON_QUICK_DRY = 'https://cdn.shinesty.com/2023-11-10/quick-dry-icon.svg';
const ICON_SEAMLESS_TOE = 'https://cdn.shinesty.com/2023-11-10/seamless-toe-icon.svg';
const ICON_SOFT = 'https://cdn.shinesty.com/2024-10-23/lounge-soft.svg';
const ICON_TAPERED_POLO = 'https://cdn.shinesty.com/2024-05-08/tapered-fit-polo.svg';
const ICON_THONG_RISE = 'https://cdn.shinesty.com/2023-11-10/thong-rise-icon.svg';
const ICON_TSHIRT_FIT = 'https://cdn.shinesty.com/2024-07-22/Tshirt%20Fit.svg';

const BOXER_ICONS = [
	{
		text: 'Ball Hammock® Pouch',
		image: ICON_BALL_HAMMOCK,
	},
	{
		text: 'Sumptuously Soft MicroModal',
		image: ICON_LUX_FEATHER,
	},
	{
		text: 'Moisture Wicking + Breathable',
		image: ICON_MOISTURE_WICKING,
	},
	{
		text: "Love Your First Pair or They're Free",
		image: ICON_HEART_OR_FREE,
	},
];

const LOUNGE_ICONS = [
	{
		text: 'Insanely Soft and Comfy',
		image: ICON_SOFT,
	},
	{
		text: 'Modern Flattering Fit',
		image: ICON_LOUNGE_FIT,
	},
	{
		text: "Love it or it's free",
		image: ICON_HEART_OR_FREE,
	},
];

const SLEEP_ICONS = [
	{
		text: 'Insanely Soft and Comfy',
		image: ICON_SOFT,
	},
	{
		text: 'Modern Flattering Fit',
		image: ICON_LOUNGE_FIT,
	},
	{
		text: "Love it or it's free",
		image: ICON_HEART_OR_FREE,
	},
];

const TSHIRT_ICONS = [
	{
		text: 'Proprietary Super Stupid-Soft Fabric Blend',
		image: ICON_FEATHER,
	},
	{
		text: "Everyman Fit, Flattering for Men Who Don't Count Carbs",
		image: ICON_TSHIRT_FIT,
	},
	{
		text: 'Love Your First Tee or Its Free',
		image: ICON_PIGGY_BANK,
	},
];

const DEFAULT_LOUNGE_CONFIG = {
	defaultDetailsDrawerOpen: false,
	imageHeight: 500,
	showCustomersAlsoBought: true,
	showCrossSell: true,
	showExpandableDetails: true,
	showRecommendedProducts: true,
	showReviews: true,
	showPromise: true,
	pdpIcons: LOUNGE_ICONS,
};

const productTypeConfigs = {
	dresses: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BIKINI_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal',
				image: ICON_LUX_FEATHER,
			},
			{
				text: 'Medium Coverage + Mid-Rise Fit',
				image: ICON_BIKINI_RISE,
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_BIKINI_LACE_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Sumptuously Soft Nylon Spandex Blend',
				image: ICON_LUX_FEATHER,
			},
			{
				text: 'Medium Coverage + Mid-Rise Fit',
				image: ICON_BIKINI_RISE,
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_BOXERS_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showPromise: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: BOXER_ICONS,
	},
	[PRODUCT_TYPE_BOXERS_BRIEF_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showPromise: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: BOXER_ICONS,
	},
	[PRODUCT_TYPE_BOXERS_COOLING_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showPromise: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Next Level Wicking + Breathability',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: 'Keeps Skin 3° Cooler',
				image: ICON_COOLING_SNOWFLAKE,
			},
			{
				text: 'Ball Hammock Support',
				image: ICON_BALL_HAMMOCK,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_BOXERS_FLY_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showPromise: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: BOXER_ICONS,
	},
	[PRODUCT_TYPE_BOXERS_LONG_FLY_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showPromise: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: BOXER_ICONS,
	},
	[PRODUCT_TYPE_BOXERS_LOOSE_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showPromise: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Insanely Soft and Comfy',
				image: ICON_SOFT,
			},
			{
				text: 'Comfortable Flattering Fit',
				image: ICON_LOUNGE_FIT,
			},
			{
				text: "Love it or it's free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_BOXERS_TRUNK_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showPromise: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: BOXER_ICONS,
	},

	[PRODUCT_TYPE_BIKINI_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
		showPromise: true,
	},
	[PRODUCT_TYPE_BIKINI_LACE_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
		showPromise: true,
	},
	[PRODUCT_TYPE_BOXER_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
		showPromise: true,
	},
	[PRODUCT_TYPE_BOXER_BRIEF_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
		showPromise: true,
	},
	[PRODUCT_TYPE_BOXER_FLY_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
		showPromise: true,
	},
	[PRODUCT_TYPE_BOXER_LONG_FLY_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
		showPromise: true,
	},
	[PRODUCT_TYPE_BOXER_TRUNK_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
		showPromise: true,
	},
	[PRODUCT_TYPE_BOXERS_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
		showPromise: true,
	},
	[PRODUCT_TYPE_BOXERS_BRIEF_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
		showPromise: true,
	},
	[PRODUCT_TYPE_BOXERS_COOLING_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BOXERS_COOLING_LONG_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BOXERS_FLY_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BOXERS_LOOSE_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BOXERS_LONG_FLY_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_POLO_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_HAWAIIAN_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_TIE_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BOWTIE_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_SWIM_BRIEF_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_SWIM_BRIEF_POUCH_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BOXERS_LONG_FLY_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BOXERS_TRUNK_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BOYS_UNDERWEAR_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BOYSHORT_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BRALETTES_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BRALETTES_COOLING_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_LONG_JOHNS_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_LOUNGE_MENS_HOODIE_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BEANIE_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_LOUNGE_MENS_JOGGER_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_ONE_PIECE_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BIKINI_BOTTOM_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_SLEEP_MENS_TOPS_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_SLEEP_MENS_BOTTOMS_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_SLEEP_WOMENS_SETS_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_SLEEP_WOMENS_DRESS_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_SOCKS_ANKLE_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_SOCKS_CREW_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_THONG_LACE_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_THONGS_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_THONGS_COOLING_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BRALETTES_TRIANGLE_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
	},
	[PRODUCT_TYPE_BIKINI_TOP_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_THONGS_MODAL_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_T_SHIRTS_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_WOMENS_BOXER_PACK_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BOYSHORT_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal',
				image: ICON_LUX_FEATHER,
			},
			{
				text: 'Full Coverage + Mid-Rise Fit',
				image: ICON_BOYSHORT_RISE,
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	'suit pants': {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	'ski suits': {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	[PRODUCT_TYPE_BRALETTES_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal',
				image: ICON_LUX_FEATHER,
			},
			{
				text: 'Lifts & Supports The Girls',
				image: ICON_BOOB_LIFT,
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_BRALETTES_COOLING_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Next Level Wicking + Breathability',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: 'Keeps Skin 3° Cooler',
				image: ICON_COOLING_SNOWFLAKE,
			},
			{
				text: 'Lifts & Supports The Girls',
				image: ICON_BOOB_LIFT,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_CHEEKY_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal',
				image: ICON_LUX_FEATHER,
			},
			{
				text: 'Medium Coverage + Mid-Rise Fit',
				image: ICON_CHEEKY_RISE,
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	'cheeky - top lace': {
		...DEFAULT_PDP_CONFIG,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal & Lace',
				image: ICON_LUX_FEATHER,
			},
			{
				text: 'Medium Coverage + Mid-Rise Fit',
				image: ICON_CHEEKY_RISE,
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_LAUNDRY_SHEETS_LOWER]: {
		defaultDetailsDrawerOpen: true,
		imageHeight: 750,
		imagesToShow: 4,
		isLaundry: true,
		showCustomersAlsoBought: true,
		showCrossSell: false,
		showRecommendedProducts: false,
		showReviews: true,
	},
	[PRODUCT_TYPE_BOXERS_BRIEF_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: BOXER_ICONS,
	},
	[PRODUCT_TYPE_LONG_JOHNS_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 700,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		showPromise: true,
		pdpIcons: BOXER_ICONS,
	},
	[PRODUCT_TYPE_LOUNGE_MENS_HOODIE_LOWER]: {
		...DEFAULT_LOUNGE_CONFIG,
	},
	[PRODUCT_TYPE_LOUNGE_MENS_JOGGER_LOWER]: {
		...DEFAULT_LOUNGE_CONFIG,
	},
	'athletic shorts - pack': {
		defaultDetailsDrawerOpen: false,
		showCustomersAlsoBought: true,
		imageHeight: 700,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Ball Hammock Support',
				image: ICON_BALL_HAMMOCK,
			},
			{
				text: 'Extremely light weight cooling fabric',
				image: ICON_COOLING_SNOWFLAKE,
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	"men's shorts": {
		defaultDetailsDrawerOpen: false,
		showCustomersAlsoBought: true,
		imageHeight: 750,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Ball Hammock Support',
				image: 'https://assets.shinesty.com/icons/Pouch_new.svg',
			},
			{
				text: 'Extremely light weight cooling fabric',
				image: 'https://assets.shinesty.com/icons/Fan.svg',
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: 'https://assets.shinesty.com/icons/Moisture.svg',
			},
			{
				text: "Love Your First Pair or They're Free",
				image: 'https://assets.shinesty.com/icons/Money.svg',
			},
		],
	},
	blazers: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 700,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Insanely soft, stretchy fabric',
				image: ICON_FABRIC_LACE,
			},
			{
				text: 'Perfect tailored fit',
				image: ICON_TAPERED_POLO,
			},
			{
				text: "Love it or it's free guarantee",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_T_SHIRTS_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
		pdpIcons: TSHIRT_ICONS,
	},
	"men's t-shirts - pack": {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 500,
		pdpIcons: TSHIRT_ICONS,
	},
	[PRODUCT_TYPE_SLEEP_MENS_TOPS_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		showPromise: true,
		pdpIcons: SLEEP_ICONS,
	},
	[PRODUCT_TYPE_SLEEP_MENS_BOTTOMS_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		showPromise: true,
		pdpIcons: SLEEP_ICONS,
	},
	[PRODUCT_TYPE_SOCKS_ANKLE_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Super Stupid-Soft™ Fabric',
				image: ICON_LUX_FEATHER,
			},
			{
				text: 'Goldilocks Perfect Fit',
				image: ICON_MEASURING,
			},
			{
				text: 'Seamless Toe',
				image: ICON_SEAMLESS_TOE,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	'socks - ankle - pack': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Super Stupid-Soft™ Fabric',
				image: ICON_LUX_FEATHER,
			},
			{
				text: 'Goldilocks Perfect Fit',
				image: ICON_MEASURING,
			},
			{
				text: 'Seamless Toe',
				image: ICON_SEAMLESS_TOE,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_SOCKS_CREW_LOWER]: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Super Stupid-Soft™ Fabric',
				image: ICON_LUX_FEATHER,
			},
			{
				text: 'Goldilocks Perfect Fit',
				image: ICON_MEASURING,
			},
			{
				text: 'Seamless Toe',
				image: ICON_SEAMLESS_TOE,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	onesies: {
		...DEFAULT_PDP_CONFIG,
		imageHeight: 700,
	},
	'socks - crew - pack': {
		defaultDetailsDrawerOpen: false,
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Super Stupid-Soft™ Fabric',
				image: ICON_LUX_FEATHER,
			},
			{
				text: 'Goldilocks Perfect Fit',
				image: ICON_MEASURING,
			},
			{
				text: 'Seamless Toe',
				image: ICON_SEAMLESS_TOE,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_THONGS_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Low Coverage + Low-Rise Fit',
				image: ICON_THONG_RISE,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_THONGS_COOLING_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Next Level Wicking + Breathability',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: 'Keeps Skin 3° Cooler',
				image: ICON_COOLING_SNOWFLAKE,
			},
			{
				text: 'Low Coverage + Low-Rise Fit',
				image: ICON_THONG_RISE,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_THONGS_MODAL_LOWER]: {
		defaultDetailsDrawerOpen: false,
		detailsComponent: 'h3',
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal',
				image: ICON_LUX_FEATHER,
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: 'Low Coverage + Mid-Rise Fit',
				image: ICON_THONG_RISE,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	trunks: {
		defaultDetailsDrawerOpen: false,
		imageHeight: 700,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		pdpIcons: [
			{
				text: 'Ball Hammock Support',
				image: ICON_BALL_HAMMOCK,
			},
			{
				text: 'Light Weight, Quick Dry Fabric',
				image: ICON_QUICK_DRY,
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_WOMENS_BOXER_LOWER]: {
		defaultDetailsDrawerOpen: false,
		detailsComponent: 'h3',
		imageHeight: 500,
		showCustomersAlsoBought: true,
		showCrossSell: true,
		showExpandableDetails: true,
		showRecommendedProducts: true,
		showReviews: true,
		showPromise: true,
		pdpIcons: [
			{
				text: 'Sumptuously Soft MicroModal',
				image: ICON_LUX_FEATHER,
			},
			{
				text: 'Moisture Wicking + Breathable',
				image: ICON_MOISTURE_WICKING,
			},
			{
				text: 'Boxer Style Cut',
				image: ICON_BOXER_CUT,
			},
			{
				text: "Love Your First Pair or They're Free",
				image: ICON_HEART_OR_FREE,
			},
		],
	},
	[PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE_LOWER]: {
		...DEFAULT_LOUNGE_CONFIG,
	},
	[PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER_LOWER]: {
		...DEFAULT_LOUNGE_CONFIG,
	},
	[PRODUCT_TYPE_SLEEP_WOMENS_DRESS_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		showPromise: true,
		pdpIcons: SLEEP_ICONS,
	},
	[PRODUCT_TYPE_SLEEP_WOMENS_SETS_LOWER]: {
		...DEFAULT_PDP_CONFIG,
		showPromise: true,
		pdpIcons: SLEEP_ICONS,
	},
};

const getProductPageConfig = (product) => {
	const productType = product.productType || product.shopifyProductType;
	const foundPdpConfig = productTypeConfigs[productType.toLowerCase()];
	return foundPdpConfig || BASIC_PDP_CONFIG;
};

module.exports = {
	getProductPageConfig,
};
