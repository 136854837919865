import MainLayout from '/layouts/MainLayout';
import NickGetsLashed from '/components/NickGetsLashed';
import React from 'react';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);

		// Define a state variable to track whether is an error or not
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI
		return { hasError: true };
	}

	componentDidCatch(error) {
		console.error('Application error:', error);
	}

	render() {
		// Check if the error is thrown
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<MainLayout>
					<NickGetsLashed title="Error" />
				</MainLayout>
			);
		}

		// Return children components in case of no error

		// eslint-disable-next-line react/prop-types
		return this.props.children;
	}
}

export default ErrorBoundary;
